import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState, useCallback, useMemo } from 'react'
import CustomSlider from '../../../../../common/CustomSlider'
import { Card } from './subcomponents'
import useClasses from '../../../../../../hooks/useClasses'
import AllCards from './subcomponents/AllCards'
import { useExplore } from '../../../../../../context/explore-context'

const Classes = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false)
  const { totalCount, classes, getClasses } = useClasses()
  const { selectedActivities } = useExplore()

  const fetchClasses = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow
      await getClasses(0, newLimit, selectedActivities)
    },
    [getClasses, itemsPerRow, selectedActivities]
  )

  useEffect(() => {
    if (showAll) return
    fetchClasses(0)
  }, [fetchClasses, showAll])

  const renderClassCards = useMemo(() => {
    return classes.map((item) => (
      <Box key={item.id} margin={0} padding="0 0 10px 10px" >
        <Card item={item} />
      </Box>
    ))
  }, [classes])

  return (
    <>
      {showAll && classes.length > 0 ? (
        <AllCards showAll={showAll} setShowAll={setShowAll} classes={classes} />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
              Classes
            </Typography>
            {classes.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>
          {classes.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchClasses}>
              {renderClassCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6" color="text.secondary">
              No classes found
            </Typography>
          )}
        </Box>
      )}
    </>
  )
}

export default Classes
