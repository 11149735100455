import { useEffect, useMemo, useState, useCallback } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import AllClubs from './subcomponent/AllClubs'
import useClubs from '../../../../../../hooks/useClubs'
import ClubCard from './subcomponent/ClubCard'
import CustomSlider from '../../../../../common/CustomSlider'
import { useExplore } from '../../../../../../context/explore-context'

const ClubSlider = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false);
  const { totalCount, clubs, getClubs } = useClubs();
  const { selectedActivities, selectedRange, currentPosition } = useExplore();

  const fetchClubs = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow;
      await getClubs(0, newLimit, selectedActivities, selectedRange, currentPosition);
    },
    [getClubs, itemsPerRow, selectedActivities, selectedRange, currentPosition]
  );

  useEffect(() => {
    if (showAll) return;
    fetchClubs(0);
  }, [fetchClubs, showAll]);

  useEffect(() => {
    getClubs(0, itemsPerRow, selectedActivities)
  }, [getClubs, selectedActivities])

  const renderedClubs = useMemo(() => {
    return clubs.map((club) => (
      <Box key={club.id} padding="0 0 10px 10px">
        <ClubCard club={club} />
      </Box>
    ))
  }, [clubs]);

  return (
    <>
      {showAll && clubs.length > 0 ? (
        <AllClubs showAll={showAll} setShowAll={setShowAll} clubs={clubs} />
      ) : (
        <Box sx={{ padding: '20px 0', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
              Clubs
            </Typography>
            {clubs.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => setShowAll((prev) => !prev)}
              >
                Show All
              </Typography>
            )}
          </Box>

          {clubs.length > 0 ? (
            <CustomSlider
              totalCount={totalCount}
              itemsPerRow={itemsPerRow}
              fetch={fetchClubs}
            >
              {renderedClubs}
            </CustomSlider>
          ) : (
            <Typography variant="h6" color="text.secondary">
              No clubs found
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default ClubSlider;
