import { useEffect, useMemo, useState, useCallback } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import TrainerCard from './subcomponet/TrainerCard'
import AllTrainers from './subcomponet/AllTrainer'
import CustomSlider from '../../../../../common/CustomSlider'
import useTrainers from '../../../../../../hooks/useTrainers'
import { useExplore } from '../../../../../../context/explore-context'

const TrainerSlider = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 6
  const [showAll, setShowAll] = useState(false)
  const { totalCount, trainers, getTrainers } = useTrainers()
  const { selectedActivities, selectedRange, currentPosition } = useExplore()

  const fetchTrainers = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow
      await getTrainers(0, newLimit, selectedActivities, selectedRange, currentPosition)
    },
    [getTrainers, itemsPerRow, selectedActivities, selectedRange, currentPosition]
  )

  useEffect(() => {
    if (showAll) return
    fetchTrainers(0)
  }, [fetchTrainers, showAll])

  const renderedTrainerCards = useMemo(() => {
    return trainers.map((trainer) => (
      <Box key={trainer.id} sx={{ marginRight: 4, width: 220 }} padding="0 0 10px 10px">
        <TrainerCard row={trainer} />
      </Box>
    ))
  }, [trainers])

  return (
    <>
      {showAll && trainers.length > 0 ? (
        <AllTrainers showAll={showAll} setShowAll={setShowAll} trainers={trainers} />
      ) : (
        <Box sx={{ padding: '20px 0', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
              Trainers
            </Typography>
            {trainers.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>

          {trainers.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchTrainers}>
              {renderedTrainerCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6" color="text.secondary">
              No trainers found
            </Typography>
          )}
        </Box>
      )}
    </>
  )
}

export default TrainerSlider
