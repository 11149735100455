import React, { useEffect, useState, useCallback, useMemo } from 'react'
import CustomSlider from '../../../common/CustomSlider'
import ActivityCard from './subcomponents/ActivityCard'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import AllActivities from './subcomponents/AllCards'
import useActivityTypes from '../../../../hooks/useActivityTypes'
import { useExplore } from '../../../../context/explore-context'
import { getSportTypeFromName } from '../../../../utils/helpers/formatSportType'

const ActivityTypes = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 6
  const { activityTypes, getActivityTypes, totalCount } = useActivityTypes()
  const [showAll, setShowAll] = useState(false)
  const { selectedActivities, setSelectedActivities } = useExplore()

  const fetchActivities = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow
      await getActivityTypes(0, newLimit)
    },
    [getActivityTypes, itemsPerRow]
  )

  useEffect(() => {
    if (showAll) return
    fetchActivities(0)
  }, [fetchActivities, showAll])

  const handleCardClick = (activityName) => {
    setSelectedActivities((prevSelectedActivities) => {
      const sportType = getSportTypeFromName(activityName)
      if (prevSelectedActivities.includes(sportType)) {
        return prevSelectedActivities.filter((name) => name !== sportType)
      } else {
        return [...prevSelectedActivities, sportType]
      }
    })
  }

  const renderActivityCards = useMemo(() => {
    return activityTypes.map((activity, index) => {
      const isSelected = selectedActivities.includes(getSportTypeFromName(activity.name))
      return (
        <Box marginRight={4} key={index}>
          <ActivityCard
            key={`${activity.name}-${index}`}
            activity={activity}
            isSelected={isSelected}
            handleCardClick={handleCardClick}
            widthBox={200}
          />
        </Box>
      )
    })
  }, [activityTypes, selectedActivities, handleCardClick])

  return (
    <>
      {showAll ? (
        <AllActivities
          selectedCards={selectedActivities}
          handleCardClick={handleCardClick}
          isShowAll={showAll}
          setIsShowAll={setShowAll}
        />
      ) : (
        <Box sx={{ padding: '20px 0' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="bold" marginLeft="10px">
              Activity Type
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => setShowAll((prev) => !prev)}>
              Show All
            </Typography>
          </Box>
          {activityTypes.length > 0 ? (
            <CustomSlider totalCount={totalCount} fetch={fetchActivities} itemsPerRow={itemsPerRow}>
              {renderActivityCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6">No activities available</Typography>
          )}
        </Box>
      )}
    </>
  )
}

export default ActivityTypes
