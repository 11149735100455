import React, { createContext, useContext, useState, useMemo } from "react";

const contextDefaultValues = {
    selectedActivities: [],
    setSelectedActivities: () => {},
    selectedRange: 1000000,
    setSelectedRange: () => {},
    currentPosition: 0,
    setCurrentPosition: () => {}
};

const ExploreContext = createContext(contextDefaultValues);

const ExploreProvider = ({ children }) => {
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [selectedRange, setSelectedRange] = useState(1000000);
    const [currentPosition, setCurrentPosition] = useState({});

    const value = useMemo(() => ({
        selectedRange,
        setSelectedRange,
        selectedActivities,
        setSelectedActivities,
        currentPosition,
        setCurrentPosition
    }), [selectedActivities, selectedRange, currentPosition]);

    return (
        <ExploreContext.Provider value={value}>
            {children}
        </ExploreContext.Provider>
    );
};

export const useExplore = () => {
    const context = useContext(ExploreContext);
    if (!context) {
        throw new Error("useExplore must be used within an ExploreProvider");
    }
    return context;
};

export default ExploreProvider;
