import queryString from 'query-string'

import axios from './axios'

export const getActivityTypesReq = async (from, limit) => {
  try {
    const req = await axios.get(`/public/newExplore/activities?from=${from}&limit=${limit}`)
    const res = await req.data

    return res
  } catch (error) {
    console.log({ error })
  }
}

export const getCoursesReq = async (from, limit) => {
  try {
    const req = await axios.get(`/public/newExplore/courses?from=${from}&limit=${limit}`)
    const res = await req.data

    return res
  } catch (error) {
    console.log({ error })
  }
}

export const getChallangesReq = async (from, limit) => {
  try {
    const req = await axios.get(`public/newExplore/challenges?from=${from}&limit=${limit}`)
    const res = await req.data

    return res
  } catch (error) {
    console.log({ error })
  }
}

export const getTrainersReq = async (from, limit, selectedActivities = [], selectedRange, currentPosition) => {
  try {
    const params = { from, limit };
    let queryString = new URLSearchParams(params).toString();

    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`;
      });
    };

    if (selectedRange) queryString += `&distance=${selectedRange}`;
    if (currentPosition.lat && currentPosition.lng) queryString += `&locationLat=${currentPosition.lat}&locationLng=${currentPosition.lng}`;

    const req = await axios.get(`public/newExplore/trainers?${queryString}`);
    const res = await req.data;

    return res;
  } catch (error) {
    console.log({ error });
  };
};

export const getClubsReq = async (from, limit, selectedActivities = [], selectedRange, currentPosition) => {
  try {
    const params = { from, limit };
    let queryString = new URLSearchParams(params).toString();

    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`;
      });
    };

    if (selectedRange) queryString += `&distance=${selectedRange}`;
    if (currentPosition.lat && currentPosition.lng) queryString += `&locationLat=${currentPosition.lat}&locationLng=${currentPosition.lng}`;

    const req = await axios.get(`public/newExplore/clubs?${queryString}`);
    const res = await req.data;

    return res;
  } catch (error) {
    console.log({ error });
  };
};

export const getServicesReq = async (from, limit) => {
  try {
    const req = await axios.get(`public/newExplore/services?from=${from}&limit=${limit}`)
    const res = await req.data

    return res
  } catch (error) {
    console.log({ error })
  }
}

export const getClubsForMapReq = async () => {
  try {
    const req = await axios.get('public/newExplore/clubs-for-map');
    const res = await req.data;

    return res;
  } catch (error) {
    console.log({ error });
  };
};

export const getTrainersForMapReq = async () => {
  try {
    const req = await axios.get('public/newExplore/trainers-for-map');
    const res = await req.data;

    return res;
  } catch (error) {
    console.log({ error });
  };
};


export const getClassesReq = async (from, limit, selectedActivities = []) => {
  try {
    const params = { from, limit };
    let queryString = new URLSearchParams(params).toString();

    // Check if selectedActivities is an array and add it to the query string
    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`;
      });
    }

    const req = await axios.get(`public/newExplore/classes?${queryString}`);
    const res = await req.data;

    return res;
  } catch (error) {
    console.log({ error });
  }
};

export const getCompanies = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/mobile/clubs?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getTrainers = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/trainers?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getServices = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/services?' + queryString.stringify({ ...query }))
    .then((res) => {
      return res.data
    })
}

export const getEvents = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios.get('/public/explore/events?' + queryString.stringify({ ...query })).then((res) => {
    return res.data
  })
}
