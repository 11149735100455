import { useEffect, useMemo, useState, useCallback } from 'react';
import useServices from '../../../../../../hooks/useServices';
import { Box, useTheme } from '@mui/system';
import { Typography, useMediaQuery } from '@mui/material';
import CustomSlider from '../../../../../common/CustomSlider';
import ServiceCard from './subcomponents/ServiceCard';
import AllServices from './subcomponents/AllServices';
import { useExplore } from '../../../../../../context/explore-context';

const ServiceSlider = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerRow = isMobile ? 1 : 4;
  const [showAll, setShowAll] = useState(false);
  const { totalCount, services, getServices } = useServices();
  const { selectedActivities } = useExplore();

  const fetchServices = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow;
      await getServices(0, newLimit, selectedActivities);
    },
    [getServices, itemsPerRow, selectedActivities]
  );

  useEffect(() => {
    if (showAll) return;
    fetchServices(0);
  }, [fetchServices, showAll]);

  const renderServiceCards = useMemo(() => {
    return services.map((service) => (
      <Box key={service.id} padding="0 0 10px 10px">
        <ServiceCard service={service} />
      </Box>
    ))
  }, [services]);

  return (
    <>
      {showAll && services.length > 0 ? (
        <AllServices
          showAll={showAll}
          setShowAll={setShowAll}
          services={services}
        />
      ) : (
        <Box sx={{ padding: '20px 0', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
              Services
            </Typography>
            {services.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => setShowAll((prev) => !prev)}
              >
                Show All
              </Typography>
            )}
          </Box>

          {services.length > 0 ? (
            <CustomSlider
              totalCount={totalCount}
              itemsPerRow={itemsPerRow}
              fetch={fetchServices}
            >
              {renderServiceCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6" color="text.secondary">
              No services found
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default ServiceSlider;
