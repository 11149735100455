import { CheckCircle, Star } from '@mui/icons-material'
import { Avatar, Box, Paper, Rating, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  BoxFlexEnd,
  BoxStyle,
  CenterBox,
  ColumnFlex,
  PaperBox,
  RadiusBox,
  RowBox
} from './index.styled'
import { getBackgroundImage } from '../../../../../../../utils/helpers/bgImages'
import { LIGHT_GRAY_BORDER_COLOR } from '../../../../../../../utils/constants'

const ClubCard = ({ club }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Link style={{ textDecoration: 'none', margin: 0, padding: 0, width: isMobile ? '100%' : '320px' }} to={`/${club.domain}`}>
      <Paper
        elevation={5}
        sx={{
          width: 350,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${getBackgroundImage(
            club.defaultSportType
          )})`,
          ...PaperBox
        }}>
        <Box display="flex" flexDirection="column" marginTop="auto" style={{ width: 350 }}>
          <Box style={BoxStyle}>
            <Box style={ColumnFlex}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  color="white"
                  noWrap
                  variant="h6"
                  fontWeight="bold"
                  maxWidth="80%"
                  flexDirection="row"
                  alignItems="center">
                  {club.clubName}
                </Typography>
                {club.verified && (
                  <Box sx={CenterBox}>
                    <CheckCircle fontSize="small" sx={{ color: 'white' }} />
                  </Box>
                )}
              </Box>

              <Typography color="white" fontSize="p" fontWeight="500" noWrap maxWidth="90%">
                {club.defaultSportType
                  ? t(`sportTypesOptions.${club.defaultSportType}`)
                  : club.address}
              </Typography>

              <Box style={RowBox}>
                <Rating
                  emptyIcon={
                    <Star
                      style={{ opacity: 0.55, color: LIGHT_GRAY_BORDER_COLOR }}
                      fontSize="inherit"
                    />
                  }
                  precision={0.1}
                  sx={{ color: 'white', fontSize: 16 }}
                  disabled
                  value={Math.min(Math.max(club.reviewsAverage, 1), 5)}
                />
                <Typography pl={1} color="white" variant="span" noWrap fontWeight="bold">
                  {club.reviewsCount} {t('explore.clubs.reviews')}
                </Typography>
              </Box>
            </Box>

            <Box sx={BoxFlexEnd}>
              <Avatar src={club.avatarPhotoSrc} alt={club.clubName} style={RadiusBox}>
                {club.clubName.charAt(0)}
              </Avatar>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Link>
  )
}

export default ClubCard
