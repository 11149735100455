import { Box, Button, Checkbox, FormControlLabel, Grid, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as Logo } from '../../../../../../../../assets/logo-simple.svg'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Filters = ({ showClubs, showTrainers, setShowClubs, setShowTrainers }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            position: 'absolute',
            top: 10,
            zIndex: 1,
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '98.8%',
            flexWrap: 'wrap',
            ...(isMobile ? { height: '660px', flexDirection: 'column', width: '100%', padding: 0 } : {})
        }}>
            <Box sx={{
                display: 'flex',
                backgroundColor: 'white',
                borderRadius: '5px',
                padding: '10px',
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                    <Logo height={40} width={40} />
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={showClubs} onChange={() => setShowClubs(!showClubs)} />}
                    label="Show Clubs"
                />
                <FormControlLabel
                    control={<Checkbox checked={showTrainers} onChange={() => setShowTrainers(!showTrainers)} />}
                    label="Show Trainers"
                />
            </Box>
            <Grid
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginLeft: 'auto',
                    ...(isMobile ? { paddingRight: '10px' } : {})
                }}
            >
                <Grid item pr={1}>
                    <Button
                        onClick={() => navigate('/login')}
                        fullWidth
                        variant="outlined"
                        sx={{ backgroundColor: 'transparent' }}
                    >
                        {t('button.login')}
                    </Button>
                </Grid>
                <Grid item width={{ lg: 150, xs: 120 }}>
                    <Button
                        fullWidth
                        onClick={() => navigate('/signup')}
                        color="primary"
                        variant="contained"
                    >
                        {t('button.signup')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filters;