import React, { useState, useEffect } from 'react'
import { Typography, Button, IconButton, Box } from '@mui/material'
import { Facebook, Instagram, Check } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'
import { useNavigate } from 'react-router-dom'

const ServiceCard = ({ service }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    id,
    services,
    text,
    isIntrested,
    intrestedLastCreatedAt: intrestedLastCreatedAtRaw,
    Company,
    Trainer,
    club,
    price,
    fbLink,
    inLink,
    website
  } = service

  const currency = Company?.currency || Trainer?.trainerCurrency
  const intrestedLastCreatedAt = new Date(intrestedLastCreatedAtRaw)
  const currentDate = new Date()
  const intrestedInSameDay =
    intrestedLastCreatedAt.getDate() === currentDate.getDate() &&
    intrestedLastCreatedAt.getMonth() === currentDate.getMonth() &&
    intrestedLastCreatedAt.getFullYear() === currentDate.getFullYear()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        width: 350,
        height: 250,
        borderRadius: 2,
        boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`,
        cursor: 'pointer'
      }}
      onClick={() => navigate(`/services/${id}`)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, padding: '4px 8px' }}>
        <Typography variant="h6" noWrap fontWeight="bold">
          {services}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="p" fontSize="small" color="#B0B0B0">
            {service.fromCompany ? t('explore.services.club') : t('explore.services.trainer')}
          </Typography>
          <Typography variant="p" color="primary" fontWeight="bold">
            {club}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography variant="p" fontSize="small" color="#B0B0B0">
            {t('explore.services.description')}
          </Typography>
          <Typography variant="p">
            {text.length > 150 ? text.slice(0, 150) + '...' : text}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button
            disabled={intrestedInSameDay || !service.user}
            variant="outlined"
            size="5px"
            sx={{ fontSize: 12, padding: -2 }}>
            {isIntrested ? <Check /> : t('explore.services.intrested')}
          </Button>
          <Button
            variant="contained"
            sx={{ fontSize: 12, padding: -4 }}
            disabled={!service.user || !price}
            size="small"
            onClick={() => navigate(`/checkout?service=${id}`)}>
            {price ? t('explore.services.buyFor', { price, currency }) : t('button.buy')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography fontSize="small">{t('explore.services.share')}</Typography>
          <IconButton onClick={() => window.open(fbLink)}>
            <Facebook color="primary" />
          </IconButton>
          <IconButton onClick={() => window.open(inLink)}>
            <Instagram color="primary" />
          </IconButton>
          <IconButton onClick={() => window.open(website)}>
            <LanguageIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceCard
