import { useCallback, useEffect, useState } from 'react'
import useServices from '../../../../../../../hooks/useServices'
import ServiceCard from './ServiceCard'
import ShowAllModal from '../../ShowAllModal'

const ITEMS_PER_PAGE = 12

const AllServices = ({ isShowAll, setIsShowAll }) => {
  const { services, getServices, totalCount } = useServices()
  const [limit, setLimit] = useState(ITEMS_PER_PAGE)
  const [currentItemsCount, setCurrentItemsCount] = useState(0)

  useEffect(() => {
    const fetchInitialServices = async () => {
      await getServices(0, limit)
      setCurrentItemsCount(services.length)
    }
    fetchInitialServices()
  }, [limit, getServices])

  const fetch = useCallback(
    async (type) => {
      const newLimit =
        type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE)
      await getServices(0, newLimit)
      setLimit(newLimit)
      setCurrentItemsCount(services.length)
    },
    [limit, getServices, services.length]
  )

  return (
    <ShowAllModal
      isShowAll={isShowAll}
      setIsShowAll={setIsShowAll}
      gap={5}
      totalCount={totalCount}
      currentItemsCount={currentItemsCount}
      fetch={fetch}>
      {services.map((service) => (
        <ServiceCard key={service.id} service={service} />
      ))}
    </ShowAllModal>
  )
}

export default AllServices
