import { useState, useCallback } from 'react'
import { getServicesReq } from '../api/explore'

const useServices = () => {
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const getServices = useCallback(async (from = 0, limit = 5) => {
    setLoading(true)
    try {
      const { count, services } = await getServicesReq(from, limit)
      setServices(services)
      setTotalCount(count)
    } catch (error) {
      console.error('Error fetching courses:', error)
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, totalCount, services, getServices }
}

export default useServices
