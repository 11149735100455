import React, { useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import {
  BoxFlexBetween,
  BoxStyle,
  BoxStyle2,
  CardComponent,
  DividerComponent,
  FlexColumn,
  TextStyle,
  BoxIcon
} from './index.styled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DialogChalange from './Dialog';

const ChallengeCard = ({ challenges }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card sx={CardComponent(challenges.imageUrl)} onClick={handleClickOpen}>
        <Box sx={BoxFlexBetween}>
          <Box sx={FlexColumn}>
            {challenges.avatar && challenges.avatar !== '' && (
              <img
                src={challenges.avatar}
                alt="Challenge Avatar"
                width="36px"
                height="36px"
                style={{margin: 0, padding: 0 }}
              />
            )}
          </Box>
          <Box sx={BoxStyle}>
            <Typography
              fontSize={'14px'}
              fontWeight={'bold'}
              fontStyle={'italic'}
              style={{ padding: '5px', backgroundColor: 'rgba(0,0,0,0.4)' }}>
              {challenges.rewardAmount > 0 ? `${challenges.rewardAmount} EUR` : 'REWARD'}
            </Typography>
            <Typography
              fontSize={'14px'}
              sx={{
                ...TextStyle,
                backgroundColor:
                  challenges.status === 'PENDING'
                    ? '#F79900'
                    : challenges.status === 'ACTIVE'
                    ? '#057AF6'
                    : challenges.status === 'ONGOING'
                    ? '#01703C'
                    : '#007bff'
              }}>
              {challenges.status}
            </Typography>
          </Box>
        </Box>
        <CardContent sx={{ paddingBottom: '16px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {challenges.title}
          </Typography>
          <Box sx={BoxStyle2}>
            <Typography variant="body2" style={BoxIcon}>
              <img
                src={
                  'https://cdn4.iconfinder.com/data/icons/simple-goods-services-outline/30/running-512.png'
                }
                width={'24px'}
                height={'24px'}
                style={{ filter: 'invert(100%)' }}
              />
              {challenges.steps}
              {''} Steps
            </Typography>
            <DividerComponent />
            <Typography variant="body2" style={BoxIcon}>
              <CalendarTodayIcon />
              {challenges.startDate}
            </Typography>
            <DividerComponent />
            <Typography variant="body2" style={BoxIcon}>
              <PeopleAltIcon color="#cccc" />
              {challenges.participants} JOINED
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <DialogChalange challenges={challenges} open={open} handleClickClose={handleClickClose} />
    </>
  );
};

export default ChallengeCard;
