import { useEffect } from 'react';
import useClubs from '../../../../hooks/useClubs';
import { CustomMap } from './subcomponents';
import useTrainers from '../../../../hooks/useTrainers';
import { CircularProgress } from '@mui/material';

const MapSection = () => {
    const { clubsForMap, getClubsForMap } = useClubs();
    const { trainersForMap, getTrainersForMap } = useTrainers();

    useEffect(() => {
        getClubsForMap();
        getTrainersForMap();
    }, []);

    return (
        <>
            {!clubsForMap || !clubsForMap.length > 0 && (<CircularProgress />)}
            {clubsForMap && clubsForMap?.length > 0 && <CustomMap clubs={clubsForMap} trainers={trainersForMap} />}
        </>
    );
};

export default MapSection;
