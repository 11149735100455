export const BoxDialog = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '10px',
  backgroundColor: '#F2F2F2',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)'
}

export const TextDialogContent = {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'start',
  flexWrap: 'wrap',
  flexDirection: 'row',
  textOverflow: 'ellipsis' 
}

export const DescriptionBox = {
  background: '#F8F8F8',
  overflow: 'auto',
  padding: '10px 15px',
  margin: '10px 0',
  borderRadius: '8px'
}
