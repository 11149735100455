import { Box, Slider, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useExplore } from "../../../../context/explore-context";

const marks = [
    { value: 0, label: '0km' },
    { value: 100000, label: '100km' },
    { value: 200000, label: '200km' },
    { value: 300000, label: '300km' },
    { value: 400000, label: '400km' },
    { value: 500000, label: '500km' },
    { value: 600000, label: '600km' },
    { value: 700000, label: '700km' },
    { value: 800000, label: '800km' },
    { value: 900000, label: '900km' },
    { value: 1000000, label: 'Max' },
];

const marksMobile = [
    { value: 0, label: '0km' },
    { value: 200000, label: '200km' },
    { value: 400000, label: '400km' },
    { value: 600000, label: '600km' },
    { value: 800000, label: '800km' },
    { value: 1000000, label: 'Max' },
];

const SearchArea = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const { selectedRange, setSelectedRange } = useExplore();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: 170 }}>
            <Typography>{t('explore.searchArea')}</Typography>
            <Slider
                size="medium"
                sx={{ width: '80%' }}
                defaultValue={1000000}
                value={selectedRange}
                onChange={(event, value) => setSelectedRange(value)}
                step={2000}
                min={0}
                max={1000000}
                marks={matchesPhone ? marksMobile : marks}
                color="primary"
            />
        </Box>
    );
};

export default SearchArea;
