import { useState, useCallback } from 'react';
import { getCoursesReq } from '../api/explore';

const useCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getCourses = useCallback(async (from = 0, limit = 5) => {
    setLoading(true);
    try {
      const { count, courses } = await getCoursesReq(from, limit);
      setCourses(courses);
      setTotalCount(count);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, totalCount, courses, getCourses };
};

export default useCourses;
