import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ChallengeCard from './Components/ChalangesCard';
import useChallenges from '../../../../../../hooks/useChallanges';
import CustomSlider from '../../../../../common/CustomSlider';
import AllCards from './Components/AllCards';

const ChallengeSlider = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerRow = isMobile ? 1 : 4;
  const [showAll, setShowAll] = useState(false);
  const { challenges, getChallenges, totalCount } = useChallenges();

  const fetchChallenges = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow;
      await getChallenges(0, newLimit);
    },
    [getChallenges, itemsPerRow]
  );

  useEffect(() => {
    if (showAll) return;
    fetchChallenges(0);
  }, [fetchChallenges, showAll]);

  const renderChallengeCards = useMemo(() => {
    return challenges.map((challenge, index) => (
      <Box key={challenge.id || index} padding="0 0 10px 10px">
        <ChallengeCard challenges={challenge} />
      </Box>
    ))
  }, [challenges]);

  return (
    <>
      {showAll && challenges.length > 0 ? (
        <AllCards isShowAll={showAll} setIsShowAll={setShowAll} />
      ) : (
        <div style={{ padding: '20px 0', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="bold" marginLeft="10px">
              Challenges
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => setShowAll((prev) => !prev)}
            >
              Show All
            </Typography>
          </Box>
          {challenges.length > 0 ? (
            <CustomSlider totalCount={totalCount} fetch={fetchChallenges} itemsPerRow={itemsPerRow}>
              {renderChallengeCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6">No challenges available</Typography>
          )}
        </div>
      )}
    </>
  );
};

export default ChallengeSlider;
