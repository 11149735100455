import { useCallback, useEffect, useState } from 'react'
import useTrainers from '../../../../../../../hooks/useTrainers'
import ShowAllModal from '../../ShowAllModal'
import TrainerCard from './TrainerCard'
import { useExplore } from '../../../../../../../context/explore-context'

const ITEMS_PER_PAGE = 12

const AllTrainers = ({ isShowAll, setIsShowAll }) => {
  const { trainers, getTrainers, totalCount } = useTrainers()
  const [limit, setLimit] = useState(ITEMS_PER_PAGE)
  const { selectedActivities } = useExplore()
  const [currentItemsCount, setCurrentItemsCount] = useState(0)

  useEffect(() => {
    const fetchInitialTrainers = async () => {
      await getTrainers(0, limit, selectedActivities)
      setCurrentItemsCount(trainers.length)
    }
    fetchInitialTrainers()
  }, [limit, getTrainers, selectedActivities, trainers.length])

  const fetch = useCallback(
    async (type) => {
      const newLimit =
        type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE)
      await getTrainers(0, newLimit, selectedActivities)
      setLimit(newLimit)
      setCurrentItemsCount(trainers.length)
    },
    [limit, getTrainers, selectedActivities, trainers.length]
  )

  return (
    <ShowAllModal
      isShowAll={isShowAll}
      setIsShowAll={setIsShowAll}
      gap={5}
      totalCount={totalCount}
      currentItemsCount={currentItemsCount}
      fetch={fetch}>
      {trainers.map((trainer) => (
        <TrainerCard key={trainer.id} row={trainer} />
      ))}
    </ShowAllModal>
  )
}

export default AllTrainers
