import { Avatar, Box, Button, Typography } from "@mui/material";
import { InfoWindow } from "@react-google-maps/api";
import { Link } from "react-router-dom";

const Callout = ({ selectedTrainer, setSelectedTrainer }) => {
    const avatarSrc = selectedTrainer.avatarPhotoSrc ? selectedTrainer.avatarPhotoSrc : 'https://i.imgur.com/Ps233FN.png';

    return (
        <InfoWindow
            position={{
                lat: selectedTrainer.locationLat,
                lng: selectedTrainer.locationLng,
            }}
            onCloseClick={() => setSelectedTrainer(null)}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 3, minHeight: 185, width: 130 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar src={avatarSrc} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{selectedTrainer.firstName} {selectedTrainer.lastName}</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>{selectedTrainer?.locationName}</Typography>
                </Box>
                <Link to={`/${selectedTrainer.domain}`}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#333'
                            },
                            transition: 'background-color 0.3s, transform 0.3s'
                        }}
                    >
                        GO THERE
                    </Button>
                </Link>
            </Box>
        </InfoWindow>
    );
};

export default Callout;
