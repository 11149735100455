import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { ClubCallout, Filters, TrainerCallout } from './subcomponents';
import { useExplore } from '../../../../../../context/explore-context';

const CustomMap = ({ clubs, trainers }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });
    const { currentPosition, setCurrentPosition } = useExplore();

    const [selectedClub, setSelectedClub] = useState(null);
    const [selectedTrainer, setSelectedTrainer] = useState(null);
    const [showClubs, setShowClubs] = useState(true);
    const [showTrainers, setShowTrainers] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentPosition({ lat: latitude, lng: longitude });
                },
                (error) => console.error("Eroare la obținerea locației: ", error)
            );
        }
    }, []);

    const mapContainerStyle = {
        width: '100%',
        height: '700px',
        outline: 'none',
        border: 0,
        position: 'relative'
    };

    const mapOptions = {
        disableDefaultUI: true,
        styles: [
            {
                featureType: "poi",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "transit",
                stylers: [{ visibility: "off" }]
            },
        ]
    };

    if (!isLoaded || !currentPosition) {
        return <CircularProgress />;
    }

    const clubMarkerIcon = {
        url: 'https://i.imgur.com/UIJq4Xg_d.webp?maxwidth=760&fidelity=grand',
        scaledSize: new window.google.maps.Size(40, 40),
    };

    const trainerMarkerIcon = {
        url: 'https://i.imgur.com/N4LWwvK.png',
        scaledSize: new window.google.maps.Size(40, 40),
    };

    return (
        <Box style={{ position: 'relative', width: '100%' }}>
            <Filters showClubs={showClubs} setShowClubs={setShowClubs} showTrainers={showTrainers} setShowTrainers={setShowTrainers} />

            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={currentPosition}
                zoom={14}
                options={mapOptions}
            >
                {showClubs && clubs?.map((club) => (
                    <Marker
                        key={club.id}
                        position={{ lat: club.locationLat, lng: club.locationLng }}
                        icon={clubMarkerIcon}
                        onClick={() => setSelectedClub(club)}
                    />
                ))}

                {showTrainers && trainers?.map((trainer) => (
                    <Marker
                        key={trainer.id}
                        position={{ lat: trainer.locationLat, lng: trainer.locationLng }}
                        icon={trainerMarkerIcon}
                        onClick={() => setSelectedTrainer(trainer)}
                    />
                ))}

                {selectedClub && (
                    <ClubCallout selectedClub={selectedClub} setSelectedClub={setSelectedClub} />
                )}

                {selectedTrainer && (
                    <TrainerCallout selectedTrainer={selectedTrainer} setSelectedTrainer={setSelectedTrainer} />
                )}
            </GoogleMap>
        </Box>
    );
};

export default CustomMap;
