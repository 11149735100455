import { useState, useCallback } from 'react'
import { getTrainersForMapReq, getTrainersReq } from '../api/explore'

const useTrainers = () => {
  const [trainers, setTrainer] = useState([]);
  const [trainersForMap, setTrainersForMap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getTrainers = useCallback(async (from = 0, limit = 5, selectedActivities, selectedRange, currentPosition) => {
    setLoading(true);
    try {
      const { count, trainers } = await getTrainersReq(from, limit, selectedActivities, selectedRange, currentPosition);

      setTrainer(trainers);
      setTotalCount(count);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setLoading(false);
    };
  }, []);

  const getTrainersForMap = useCallback(async () => {
    setLoading(true);
    try {
      const { count, trainers } = await getTrainersForMapReq();

      setTrainersForMap(trainers);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setLoading(false);
    };
  }, []);

  return { loading, totalCount, trainersForMap, trainers, getTrainers, getTrainersForMap }
}

export default useTrainers
