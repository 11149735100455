import { Container, Grid } from '@mui/material'
import { SearchArea, Sections, ActivityTypes, MapSection } from './subcomponents'

const ExploreV2 = () => {
  return (
    <Grid container>
      <MapSection />
      <SearchArea />
      <Container maxWidth="xl">
        <ActivityTypes />
      </Container>
      <Sections />
    </Grid>
  )
}

export default ExploreV2
