import { useCallback, useState } from 'react'
import { getClubsForMapReq, getClubsReq } from '../api/explore'

const useClubs = () => {
  const [clubs, setClubs] = useState([]);
  const [clubsForMap, setClubsForMap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getClubs = useCallback(async (from = 0, limit = 5, selectedActivities, selectedRange, currentPosition) => {
    setLoading(true);
    try {
      const { count, clubs: clubsRes } = await getClubsReq(from, limit, selectedActivities, selectedRange, currentPosition);

      setClubs(clubsRes);
      setTotalCount(count);
    } catch (error) {
      console.error('Error fetching clubs', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getClubsForMap = useCallback(async () => {
    setLoading(true)
    try {
      const { count, clubs: clubsRes } = await getClubsForMapReq();

      setClubsForMap(clubsRes);
    } catch (error) {
      console.error('Error fetching clubs', error);
    } finally {
      setLoading(false);
    };
  }, []);

  return { clubs, clubsForMap, loading, getClubs, getClubsForMap, totalCount }
}

export default useClubs
